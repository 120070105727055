import React from "react";

const WaveSvg = ({ className }) => {
  const stopStyle1 = {
    stopColor: "#FFF1A3",
    stopOpacity: 0.79,
  };

  const stopStyle2 = {
    stopColor: "#58003D",
    stopOpacity: 1,
  };
  return (
    <div className={className}>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="waveGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={stopStyle1} />
            <stop offset="95%" style={stopStyle2} />
          </linearGradient>
        </defs>

        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          className="shape-fill"
          fill="url(#waveGradient)"
        ></path>
      </svg>
    </div>
  );
};

export default WaveSvg;
