import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
`;

const LogoIcon = ({ className }) => {
  return (
    <LogoWrapper className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="95"
        height="48"
        viewBox="0 0 95 48"
        fill="none"
      >
        <style>
          {`
            @keyframes drawLine {
              0% {
                stroke-dashoffset: 200; 
              }
              100% {
                stroke-dashoffset: 0;
              }
            }

            path {
              stroke-dasharray: 200; 
              stroke-dashoffset: 200;
              animation: drawLine 4s linear infinite; 
            }
          `}
        </style>
        <path
          d="M33.0866 25.61H39.9066L36.6066 17.47H36.3866L33.0866 25.61ZM39.0266 15.6L45.2966 31H42.1286L40.6766 27.26H32.3166L30.8646 31H27.6966L33.9666 15.6H39.0266ZM66.8634 26.93C66.8634 27.5607 66.7314 28.1547 66.4674 28.712C66.2034 29.2547 65.7708 29.7313 65.1694 30.142C64.5828 30.538 63.8128 30.8533 62.8594 31.088C61.9208 31.3227 60.7621 31.44 59.3834 31.44C58.6061 31.44 57.8434 31.3813 57.0954 31.264C56.3621 31.1467 55.6581 30.978 54.9834 30.758C54.3234 30.538 53.7074 30.274 53.1354 29.966C52.5781 29.6433 52.0941 29.2913 51.6834 28.91L52.7834 27.59C53.6194 28.3087 54.5948 28.8587 55.7094 29.24C56.8241 29.6067 58.0488 29.79 59.3834 29.79C60.8648 29.79 61.9134 29.5847 62.5294 29.174C63.1454 28.7633 63.4534 28.1987 63.4534 27.48C63.4534 26.9227 63.2114 26.4533 62.7274 26.072C62.2434 25.6907 61.4221 25.3167 60.2634 24.95L56.0834 23.63C54.7048 23.19 53.6634 22.6327 52.9594 21.958C52.2554 21.2687 51.9034 20.396 51.9034 19.34C51.9034 18.7973 52.0281 18.2767 52.2774 17.778C52.5414 17.2647 52.9448 16.8173 53.4874 16.436C54.0301 16.04 54.7194 15.732 55.5554 15.512C56.4061 15.2773 57.4254 15.16 58.6134 15.16C59.3321 15.16 60.0434 15.2187 60.7474 15.336C61.4661 15.4533 62.1481 15.622 62.7934 15.842C63.4534 16.062 64.0621 16.3333 64.6194 16.656C65.1914 16.964 65.6828 17.3087 66.0934 17.69L64.9934 19.01C64.1574 18.2913 63.2188 17.7487 62.1774 17.382C61.1361 17.0007 59.9481 16.81 58.6134 16.81C57.5134 16.81 56.6848 17.0007 56.1274 17.382C55.5848 17.7633 55.3134 18.2327 55.3134 18.79C55.3134 19.098 55.3648 19.3693 55.4674 19.604C55.5701 19.824 55.7388 20.0367 55.9734 20.242C56.2228 20.4327 56.5454 20.616 56.9414 20.792C57.3374 20.9533 57.8288 21.1293 58.4154 21.32L62.5734 22.64C63.9081 23.0653 64.9568 23.6227 65.7194 24.312C66.4821 25.0013 66.8634 25.874 66.8634 26.93Z"
          stroke="white"
          strokeWidth="2"
        />
        <line x1="0.5" y1="4" x2="0.5" y2="14" stroke="white" />
        <line x1="94.5" y1="4" x2="94.5" y2="14" stroke="white" />
        <line y1="3.5" x2="95" y2="3.5" stroke="white" />
        <line
          y1="-0.5"
          x2="10"
          y2="-0.5"
          transform="matrix(0 -1 -1 0 0 44)"
          stroke="white"
        />
        <line
          y1="-0.5"
          x2="10"
          y2="-0.5"
          transform="matrix(0 -1 -1 0 94 44)"
          stroke="white"
        />
        <line
          y1="-0.5"
          x2="95"
          y2="-0.5"
          transform="matrix(1 0 0 -1 0 44)"
          stroke="white"
        />
      </svg>
    </LogoWrapper>
  );
};

export default LogoIcon;
